var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"border-bottom"},[[_c('thead',{staticClass:"blue-grey lighten-5"},[_c('tr',{staticClass:"text-caption grey--text "},[_c('th',{staticClass:"trainings__icon"}),_c('th',{staticClass:"text-left font-weight-regular"},[_vm._v(" Название ")]),_c('th',{staticClass:"text-left font-weight-regular"},[_vm._v(" Специалист ")]),_c('th',{staticClass:"text-left font-weight-regular"},[_vm._v(" Кол-во пользователей ")]),_c('th',{staticClass:"text-left font-weight-regular"},[_vm._v(" Дата создания ")]),_c('th')])]),_c('tbody',[_vm._l((_vm.parentGroups),function(item){return [_c('tr',{key:item.id},[_c('td',{staticClass:"w-fit px-1"},[(_vm.subCatsCount(item.id))?_c('v-btn',{staticClass:"trainings__result-count blue-grey lighten-4 px-1",attrs:{"x-small":"","tile":"","depressed":""},on:{"click":function($event){_vm.opened == item.id ? _vm.opened = null : _vm.opened= item.id}}},[_vm._v(" "+_vm._s(_vm.subCatsCount(item.id))+" ")]):_vm._e()],1),_c('td',{attrs:{"width":"30%"}},[_c('strong',[_vm._v(_vm._s(item.name))])]),_c('td',[_vm._v(_vm._s(item.expert.name))]),_c('td',[_vm._v(_vm._s(item.users_count))]),_c('td',[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))]),_c('td',{staticClass:"three-actions__width"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.addChild(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-thick")])],1)]}}],null,true)},[_vm._v(" Добавить подгруппу ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-package-down")])],1)]}}],null,true)},[_vm._v(" Отправить в архив ")])],1)])]),_vm._l((_vm.childItems(item.id)),function(child){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.opened == item.id),expression:"opened == item.id"}],key:child.id},[_c('td',{staticClass:"w-fit px-1"}),_c('td',{staticClass:"pl-8",attrs:{"width":"30%"}},[_vm._v(_vm._s(child.name))]),_c('td',[_vm._v(_vm._s(child.expert.name))]),_c('td',[_vm._v(_vm._s(child.users_count))]),_c('td',[_vm._v(_vm._s(_vm.dateFormat(child.created_at)))]),_c('td',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.edit(child.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Редактировать ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.remove(child.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-package-down")])],1)]}}],null,true)},[_vm._v(" Отправить в архив ")])],1)])])})]})],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }