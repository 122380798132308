<template>
  <div>
    <v-btn dark color="teal" @click="openCreateForm" class="mt-3 mb-6">Добавить группу</v-btn>
    <users-groups-list ref="list" @remove="confirmRemove" @edit="openEditForm" @addChild="addChild"/>
    <v-btn class="mt-5" color="blue-grey lighten-3">Архив</v-btn>
    <remove-dialog :open="openRemoveDialog" @confirm="remove" @close="closeRemoveDialog">
      <template #title>Отправить группу в архив</template>
      <template #body>{{ removeText }}</template>            
    </remove-dialog>
    <users-groups-form :open="openForm" :editId="editId" :parentForChildId="parentForChildId" @close="closeForm"/>
  </div>
</template>

<script>
 
import UsersGroupsList from './UsersGroupsList'
import UsersGroupsForm from './UsersGroupsForm'
import RemoveDialog from '../main/RemoveDialog'
export default {
  components: {
    UsersGroupsList,
    UsersGroupsForm,
    RemoveDialog
  },
  data: () => ({
    openForm: false,
    openRemoveDialog: false,
    removeId: null,
    editId: null,
    parentForChildId: null
  }),
  computed: {
    removeText() {
      let text = `Вы действительно хотите отправить группу в архив?`;
      if (this.groups.filter(g => g.parent_id == this.removeId).length) {
        text = `Вы действительно хотите отправить группу и все ее подгруппы в архив?`;
      }
      return text;
    },
    groups() {
      return this.$store.state.userGroups;
    },
  },
  methods: {
    openEditForm(id) {
      this.editId = id;
      this.openForm = true;
    },
    addChild(id) {
      this.parentForChildId = id;
      this.openForm = true;
    },
    openCreateForm() {
      this.editId = null;
      this.openForm = true;
    },
    closeForm() {
      this.editId = null;
      this.openForm = false;
    },
    confirmRemove(id) {
      this.removeId = id;
      this.openRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.removeId = null;
      this.openRemoveDialog = false;
    },
    remove() {
      let id = this.removeId;
      let url = `/users-groups/${id}`;
      this.openRemoveDialog = false;
       this.$api.delete(url)
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('addMessage', 'Группа отправлена в архив')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>