<template>
  <v-dialog v-model="open" persistent transition="dialog-top-transition" max-width="800">
    <v-card>
      <v-card-title class='mb-7'>
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row >
            <v-col cols="12" sm="12">
              <v-text-field v-model="name" :rules="[rules.requiredName]" label="Название группы" color="teal"></v-text-field>
            </v-col>
            <v-col v-if="isAdmin" cols="12" sm="12">
              <v-select
                v-model="owner"
                :items="filtredExperts"
                itemText="name"
                itemValue="id"
                label="Специалист"
                color="teal"
                item-color="teal lighten-1"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                v-model="parent"
                :items="parentsGroups"
                item-value="id"
                item-text="name"
                label="Родительская группа"
                color="teal"
                item-color="teal lighten-1"
                clearable
              ></v-select>
            </v-col>
            
          </v-row>    
        </v-form>
        <v-alert
          text
          dense
          color="blue-grey"
          icon="mdi-help-circle-outline"
          border="left"
          class="mt-5 text-caption"
        >
          <p>Для создания подгруппы введите в поле "Родительская группа" название группы, к которой будет 
            относится создаваемая подгруппа.</p>
          <p>Для создания корневой(родительской группы) оставьте поле "Родительская группа" незаполненным.</p>
        </v-alert>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey lighten-2" @click="close">
          Отмена
        </v-btn>
        <v-btn dark color="teal" @click="save">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
 
export default {
  props: {
    editId: null,
    parentForChildId: null,
    open: {
      default: false,
      type: Boolean
    },
  },
  data () {
    return {
      name: '',  
      parent: null,
      valid: false,
      owner: null,
      rules: {
        requiredName: value => !!value || 'Введите имя',
      },
    }
  },
  watch: {
    editId() {
      let group = this.groups.find(g => g.id == this.editId);
      if (group) {
        this.name = group.name;
        this.parent = group.parent_id;
        this.owner = group.expert.id;
      } else {
        this.clear();
      }
    },
    parentForChildId() {      
      this.parent = this.parentForChildId;
    },
  },  
  computed: {
    btnText() {
      return this.editId ? 'Обновить' : 'Добавить'
    },
    dialogTitle() {
      return this.editId ? 'Обновить группу' : 'Добавить группу'
    },
    groups() {
      return this.$store.state.userGroups;
    },
    parentsGroups() {
      return this.groups.filter(g => g.parent_id == null)
    },
    experts() {
      return this.$store.state.experts;
    },
    filtredExperts() {
      return this.experts;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {

    clear() {
      this.name = '';  
      this.parent = null;
    },
    close() {
      this.clear();
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.editId) {
          this.patch()
        } else {
          this.store()
        }
      }
    },
    store() {
      let url = '/users-groups'
       this.$api.post(url,{name: this.name, parent_id: this.parent, owner: this.owner})
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('addMessage', 'Группа сохранена');
          this.close();
        })
        .catch(error => {     
          console.log(error);      
          this.$store.dispatch('addRequestError', error)
        })
    },
    patch() {
      let url = `/users-groups/${this.editId}`
       this.$api.patch(url,{name: this.name, parent_id: this.parent, owner: this.owner})
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('addMessage', 'Изменения сохранены')
          this.close();
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>