var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-2 justify-space-between",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{attrs:{"dark":"","color":"teal"},on:{"click":_vm.openCreateForm}},[_vm._v("Добавить пользователя")])],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"4","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.sortingItems,"item-value":"value","label":"Сортировать по","dense":"","hide-details":"","color":"teal","item-color":"teal lighten-1"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex w-100"},[_vm._v(" "+_vm._s(item.text)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ml-2"},[_vm._v(_vm._s(item.icon))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex w-100"},[_vm._v(" "+_vm._s(item.text)+" "),_c('v-spacer'),_c('v-icon',{staticClass:"ml-2"},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true),model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}})],1)],1),_c('v-card',{staticClass:"rounded-0 pa-5 mb-6",attrs:{"color":"blue-grey lighten-5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","clearable":"","color":"teal","label":"Поиск..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"flex-grow-0 ",attrs:{"items":_vm.experts,"item-value":"id","item-text":"name","dense":"","hide-details":"","clearable":"","label":"Специалист","color":"teal","item-color":"teal lighten-1"},model:{value:(_vm.expertId),callback:function ($$v) {_vm.expertId=$$v},expression:"expertId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"flex-grow-0",attrs:{"items":_vm.userGroups,"item-value":"id","item-text":"name","dense":"","hide-details":"","clearable":"","label":"Группа","color":"teal","item-color":"teal lighten-1"},model:{value:(_vm.groupId),callback:function ($$v) {_vm.groupId=$$v},expression:"groupId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"flex-grow-0 ",attrs:{"items":_vm.modes,"item-value":"key","item-text":"name","dense":"","hide-details":"","clearable":"","label":"Режим","color":"teal","item-color":"teal lighten-1"},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}})],1)],1)],1),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.update}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}],null,true)},[_c('span',[_vm._v("Обновить")])])],1),_c('users-list',{ref:"list",attrs:{"users":_vm.sortedUsers},on:{"remove":_vm.confirmRemove,"edit":_vm.openEditForm}}),_c('remove-dialog',{attrs:{"open":_vm.openRemoveDialog},on:{"confirm":_vm.remove,"close":_vm.closeRemoveDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Удалить пользователя?")]},proxy:true},{key:"body",fn:function(){return [_vm._v("Вы действительно хотите удалить пользователя?")]},proxy:true},{key:"btn",fn:function(){return [_vm._v("Удалить")]},proxy:true}])}),_c('users-form',{attrs:{"users":_vm.users,"open":_vm.openForm,"editId":_vm.editId},on:{"close":_vm.closeForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }