<template>
  <div>
    <v-row align="center" class="mb-2 justify-space-between">
      <v-col cols="12" sm="2">
        <v-btn dark color="teal" @click="openCreateForm" >Добавить пользователя</v-btn>
      </v-col>
      <v-col cols="12" lg="2" md="4" sm="5">
        <v-select
          v-model="sort"
          :items="sortingItems"
          item-value="value"
          label="Сортировать по"
          dense
          hide-details
          color="teal"
          item-color="teal lighten-1"
        >
        <template  #[`selection`]="{ item }">
          <div class="d-flex w-100">
            {{ item.text }}
            <v-spacer/>
            <v-icon class="ml-2">{{ item.icon }}</v-icon>
          </div>
        </template>
        <template  #[`item`]="{ item }">
          <div class="d-flex w-100">
            {{ item.text }}
            <v-spacer/>
            <v-icon class="ml-2">{{ item.icon }}</v-icon>
          </div>
        </template>
      </v-select>
      </v-col>
    </v-row>
    <v-card color="blue-grey lighten-5" class="rounded-0 pa-5 mb-6">
      <v-row>
        <v-col cols="12" sm="3"> 
          <v-text-field 
            v-model="search" 
            dense
            hide-details
            clearable
            color="teal"
            label="Поиск...">
          </v-text-field>    
        </v-col>
        <v-col v-if="isAdmin" cols="12" sm="3"> 
          <v-select
            v-model="expertId"
            :items="experts"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Специалист"
            color="teal"
            class="flex-grow-0 "
            item-color="teal lighten-1">
          </v-select>       
        </v-col>   
        <v-col cols="12" sm="3">  
          <v-select
            v-model="groupId"
            :items="userGroups"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Группа"
            color="teal"
            class="flex-grow-0"
            item-color="teal lighten-1">
          </v-select>      
        </v-col> 
        <v-col cols="12" sm="3"> 
          <v-select
            v-model="mode"
            :items="modes"
            item-value="key"
            item-text="name"
            dense
            hide-details
            clearable
            label="Режим"
            color="teal"
            class="flex-grow-0 "
            item-color="teal lighten-1">
          </v-select>
        </v-col>     
      </v-row>
    </v-card>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template #[`activator`]="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="update"><v-icon>mdi-reload</v-icon></v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </div>
    <users-list :users="sortedUsers"  ref="list" @remove="confirmRemove" @edit="openEditForm"/>
    <!-- <v-btn class="mt-5" color="blue-grey lighten-3">Архив</v-btn> -->
    <remove-dialog :open="openRemoveDialog" @confirm="remove" @close="closeRemoveDialog">
      <template #title>Удалить пользователя?</template>
      <template #body>Вы действительно хотите удалить пользователя?</template>            
      <template #btn>Удалить</template>            
    </remove-dialog>
    <users-form :users="users" :open="openForm" :editId="editId" @close="closeForm"/>
  </div>
</template>

<script>
 
import UsersList from './UsersList'
import UsersForm from './UsersForm'
import RemoveDialog from '../main/RemoveDialog'
export default {
  components: {
    UsersList,
    UsersForm,
    RemoveDialog
  },
  data: () => ({
    openForm: false,
    openRemoveDialog: false,
    removeId: null,
    editId: null,
    expertId: null,
    groupId: null,
    sort: '-created',
    search: '',
    mode: null,
    modes: [
      {'key' : 'free', 'name' : 'Свободный'},
      {'key' : 'training', 'name' : 'По программе'},
    ],
    sortingItems: [
      { value: 'doctor', text:'Имя специалиста', icon: 'mdi-sort-alphabetical-ascending'  },
      { value: '-doctor', text:'Имя специалиста', icon: 'mdi-sort-alphabetical-descending'  },
      { value: 'name', text:'Имя пользователя', icon: 'mdi-sort-alphabetical-ascending'  },
      { value: '-name', text:'Имя пользователя', icon: 'mdi-sort-alphabetical-descending'  },
      { value: 'created', text:'Дата создания', icon: 'mdi-sort-ascending'  },
      { value: '-created', text:'Дата создания', icon: 'mdi-sort-descending'  },
      { value: 'training', text:'Дата последней тренировки', icon: 'mdi-sort-ascending'  },
      { value: '-training', text:'Дата последней тренировки', icon: 'mdi-sort-descending'  },
    ],
  }),
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    users() {
      return this.$store.state.users;
    },
    experts() {
      return this.$store.state.experts;
    },
    userGroups() {
      return this.$store.state.userGroups;
    },
    sortedUsers() {
      let users = this.users;
      if (this.expertId) {
        users = users.filter(u => u.doctor.id == this.expertId);
      }
      if (this.groupId) {
        users = users.filter(u => u.group.id == this.groupId);
      }
      if (this.mode) {
        users = users.filter(u => u.mode == this.mode);
      }
      if (this.search) {
        users = users.filter(u => u.name.toLowerCase().includes(this.search.toLowerCase()) || u.email.toLowerCase().includes(this.search.toLowerCase()) || u.tags.map(t => t.name).join(' ').toLowerCase().includes(this.search.toLowerCase()));
      }
      switch (this.sort) {
        case '-created':
          users.sort((a,b) => (a.created_at < b.created_at) ? 1 : ((b.created_at < a.created_at) ? -1 : 0));
          break;
        case 'created':
          users.sort((a,b) => (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0));
          break;
        case '-training':
          users.sort((a,b) => (a.training_at < b.training_at) ? 1 : ((b.training_at < a.training_at) ? -1 : 0));
          break;
        case 'training':
          users.sort((a,b) => (a.training_at && a.training_at > b.training_at) ? 1 : ((b.training_at && b.training_at > a.training_at) ? -1 : 0));
          break;
        case '-name':
          users.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0));
          break;
        case 'name':
          users.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          break;
        case '-doctor':
         users.sort((a,b) => (a.doctor.name < b.doctor.name) ? 1 : ((b.doctor.name < a.doctor.name) ? -1 : 0));
          break;
        case 'doctor':
          users.sort((a,b) => (a.doctor.name > b.doctor.name) ? 1 : ((b.doctor.name > a.doctor.name) ? -1 : 0));
          break;
        default:
          break;
      }
      return users;
    }
  },
  methods: {
    openEditForm(id) {
      this.editId = id;
      this.openForm = true;
    },
    openCreateForm() {
      this.editId = null;
      this.openForm = true;
    },
    closeForm() {
      this.editId = null;
      this.openForm = false;
    },
    confirmRemove(id) {
      this.removeId = id;
      this.openRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.removeId = null;
      this.openRemoveDialog = false;
    },
    update() {
      this.$store.dispatch('getUserGroups');
      this.$store.dispatch('getUsers');
      this.$store.dispatch('addMessage', 'Список обновлен')
    },
    remove() {
      let id = this.removeId;
      let url = `/users/${id}`;
      this.openRemoveDialog = false;
       this.$api.delete(url)
        .then(() => {
          this.$store.dispatch('getUserGroups');
          this.$store.dispatch('getUsers');
          this.$store.dispatch('addMessage', 'Пользователь удален')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
  }
}
</script>

<style>

</style>