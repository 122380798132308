<template>
  <v-simple-table class="border-bottom">
    <template>
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th v-if="isAdmin" class="text-left font-weight-regular">
            Специалист
          </th>
          <th class="text-left font-weight-regular">
            ФИО
          </th>
          <th class="text-left font-weight-regular">
            Дата рождения
          </th> 
          <th class="text-left font-weight-regular">
            Пол
          </th>
          <th class="text-left font-weight-regular">
            Email
          </th>
          <!-- <th class="text-left font-weight-regular">
            Шлем
          </th> -->
          <th class="text-left font-weight-regular">
            Группа
          </th>
          <th class="text-left font-weight-regular">
            Теги
          </th>
          <th class="text-left font-weight-regular">
            Дата создания
          </th>
          <th class="text-left font-weight-regular">
            Дата последней тренировки
          </th>
          <th class="text-left font-weight-regular">
            Режим тренировки
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item) in users" :key="item.id" @click="$router.push(`/users/${item.id}`);" class="cursor-pointer">
          <td v-if="isAdmin">{{ item.doctor.name }}</td>
          <td>{{ item.name }}</td>
          <td>{{ birthdayFormat(item.birthday) }}</td>
          <td>{{ item.sex ? (item.sex == 'm' ? 'м' : 'ж') : '' }}</td>
          <td>{{ item.email }}</td>
          <!-- <td>{{ item.helmet.name }}</td> -->
          <td>{{ item.group.deep_name }}</td>
          <td>{{ item.tags.map((t) => { return t.name}).join(', ') }}</td>
          <td>{{ dateFormat(item.created_at) }}</td>
          <td>{{ dateFormat(item.training_at) }}</td>
          <td>{{ item.mode ? (item.mode == 'free' ? 'Свободный' : 'По программе') : '' }}</td>
          <td class="two-actions__width">
            <div class="d-flex">
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click.stop="edit(item.id)" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                Редактировать
              </v-tooltip>
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click.stop="remove(item.id)"  icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                Удалить
              </v-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    users: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    
    remove(id) {
      this.$emit("remove",id);
    },
    edit(id) {
      this.$emit("edit",id);
    },
    dateFormat(date) {
      if (!date) {
        return '';
      }
      return this.$moment(date).format("DD.MM.YYYY HH:mm")
    },
    birthdayFormat(date) {
      if (!date) {
        return '';
      }
      return this.$moment(date).format("DD.MM.YYYY")
    }
  },
}
</script>

<style>

</style>