<template>
  <v-simple-table class="border-bottom">
    <template>
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th class="trainings__icon"></th>
          <th class="text-left font-weight-regular">
            Название
          </th>
          <th class="text-left font-weight-regular">
            Специалист
          </th>
          <th class="text-left font-weight-regular">
            Кол-во пользователей
          </th>
          <th class="text-left font-weight-regular">
            Дата создания
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in parentGroups" >
          <tr :key="item.id">
            <td class="w-fit px-1">
                <v-btn v-if="subCatsCount(item.id)" 
                  x-small 
                  tile 
                  depressed 
                  @click="opened == item.id ? opened = null : opened= item.id" 
                  class="trainings__result-count blue-grey lighten-4 px-1">
                  {{ subCatsCount(item.id) }}
                </v-btn>
            </td>
            <td width="30%"><strong>{{ item.name }}</strong></td>
            <td>{{ item.expert.name }}</td>
            <td>{{ item.users_count }}</td>
            <td>{{ dateFormat(item.created_at) }}</td>          
            <td class="three-actions__width">
              <div class="d-flex">
                <v-tooltip top>
                  <template #[`activator`]="{ on, attrs }">
                    <v-btn @click="addChild(item.id)"  icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-plus-thick</v-icon>
                    </v-btn>
                  </template>
                  Добавить подгруппу
                </v-tooltip>
                <v-tooltip top>
                  <template  #[`activator`]="{ on, attrs }">
                    <v-btn @click="edit(item.id)" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Редактировать
                </v-tooltip>
                <v-tooltip top>
                  <template #[`activator`]="{ on, attrs }">
                    <v-btn @click="remove(item.id)" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-package-down</v-icon>
                    </v-btn>
                  </template>
                  Отправить в архив
                </v-tooltip>
              </div>
            </td>
          </tr>
          <tr v-show="opened == item.id" v-for="child in childItems(item.id)" :key="child.id">
            <td class="w-fit px-1"></td>
            <td width="30%" class="pl-8">{{ child.name }}</td>
            <td>{{ child.expert.name }}</td>
            <td>{{ child.users_count }}</td>
            <td>{{ dateFormat(child.created_at) }}</td>          
            <td>
              <div class="d-flex justify-end">
                <v-tooltip top>
                  <template #[`activator`]="{ on, attrs }">
                    <v-btn @click="edit(child.id)" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Редактировать
                </v-tooltip>
                <v-tooltip top>
                  <template #[`activator`]="{ on, attrs }">
                    <v-btn @click="remove(child.id)"  icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-package-down</v-icon>
                    </v-btn>
                  </template>
                  Отправить в архив
                </v-tooltip>
              </div>
            </td>            
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
    }
  },
  data: () => ({
    opened: null,
  }),
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    groups() {
      return this.$store.state.userGroups;
    },
    parentGroups() {
      return this.groups.filter(g => g.parent_id == null)
    }
  },
  methods: {
    childItems(id) {
      return this.groups.filter(g => g.parent_id == id);
    },
    subCatsCount(id) {
      return this.childItems(id).length;
    },
    
    remove(id) {
      this.$emit("remove",id);
    },
    edit(id) {
      this.$emit("edit",id);
    },
    addChild(id) {
      this.$emit("addChild",id);
    },
    dateFormat(date) {
      if (!date) {
        return '';
      }
      return this.$moment(date).format("DD.MM.YYYY HH:mm")
    },
  },
}
</script>

<style>

</style>