<template>
  <div class="users">
    <page-title :title="'Пользователи'"></page-title>
    <v-tabs
        v-model="activeTab"
        class="custom__tabs"
        hide-slider
        show-arrows
        background-color="transparent"
      >
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          <v-icon left>mdi-account</v-icon>
          Пользователи
        </v-tab >
        <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
          <v-icon left>mdi-account-multiple</v-icon>
          Группы / Подгруппы          
        </v-tab>
    </v-tabs>
    <v-card class="pa-3 rounded-0 flat custom__card">
      <v-tabs-items touchless v-model="activeTab">
        <v-tab-item class="pt-2">
          <users-tab></users-tab>         
        </v-tab-item>
        <v-tab-item>
          <users-groups-tab></users-groups-tab>
        </v-tab-item>
      </v-tabs-items>        
    </v-card>
  </div>
</template>
<script>
import PageTitle from '../../components/main/PageTitle'
import UsersTab from '../../components/users/UsersTab'
import UsersGroupsTab from '../../components/users/UsersGroupsTab'
export default {
  components: {
    PageTitle,
    UsersTab,
    UsersGroupsTab
  },
  data: () => ({
    activeTab: 0,
  }),
}
</script>
<style lang="scss">
  .w-72 {
    width: 72px;
  }
</style>